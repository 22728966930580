.video {
  margin: 0 auto 3.2rem;

  overflow: hidden;

  @include respond(tablet) {
    height: 31.5rem;
  }

  &__headline {
    margin-bottom: 2.4rem;
  }
  &__track {
    list-style: none;
    transition: 0.5s ease-in-out;
    display: grid;
    grid-template-columns: repeat(5, min-content);
    place-content: center;
    grid-gap: 2.4rem;
    &--slide {
      &-vid {
        border-radius: 1rem;
      }
    }
  }

  &__btns {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    justify-content: center;
    gap: 1.6rem;
    margin-bottom: $margin-section-mobile;
    @include respond(tablet) {
      margin-bottom: $margin-section-tablet;
    }
    @include respond(desktop) {
      margin-bottom: $margin-section-desktop;
    }
    &--btn {
      background-color: $color-black;
      width: 6.4rem;
      height: 6.4rem;
      border-radius: 50%;
      display: grid;
      place-content: center;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: $color-blue;
      }
    }
  }
}
