.bio {
  text-align: center;
  background-color: $color-cream;
  margin-bottom: $margin-section-mobile;

  @include respond(tablet) {
    display: grid;
    grid-template-columns: min-content max-content;
    align-items: center;
    justify-content: center;
    gap: 6.4rem;
    margin-bottom: $margin-section-tablet;
  }
  @include respond(desktop) {
    margin-bottom: $margin-section-desktop;
  }
  &__avatar {
    margin-bottom: 1.6rem;
    &--img {
      border-radius: 50%;
      max-width: 24rem;
    }
  }
  &__headline {
    text-align: center;
    margin-bottom: 2.4rem;
  }

  &__content {
    padding: 2.4rem;
    margin: 0 2.4rem;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0.5rem 0.5rem 5rem 1rem rgba(0, 0, 0, 0.1);

    max-width: 32.7rem;

    &--icon {
      &-img {
        height: 4.8rem;
      }
    }
    &--text {
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }
}
