.topnav {
  background-color: $color-cream;
  display: grid;
  grid-template-columns: repeat(2, min-content);
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2.4rem 1.6rem 1.6rem;
  position: sticky;
  top: 0;
  z-index: 1;
  @include respond(desktop) {
    margin-bottom: $margin-section-tablet;
    padding: 0.5rem 3.2rem 1.6rem 2.4rem;
    grid-template-columns: 1fr min-content;
  }

  &__logo {
    cursor: pointer;
    &--img {
      height: 6.4rem;
    }
  }
  &__icon {
    z-index: 2;
    cursor: pointer;

    @include respond(desktop) {
      display: none;
    }
  }
  &__menu {
    position: fixed;
    text-align: right;
    right: 0;
    top: 0;
    height: 100vh;
    width: 25rem;
    right: -25rem;
    background-color: $color-white;
    padding: 10rem 4.8rem 0 0;
    display: grid;
    grid-template-rows: repeat(8, max-content);
    row-gap: 2.4rem;
    transition: 0.5s ease-in-out;
    z-index: 1;

    @include respond(desktop) {
      position: relative;
      background: none;
      grid-template-columns: repeat(9, max-content);
      column-gap: 4rem;
      grid-template-rows: 1fr;
      height: 100%;
      width: 100%;
      right: 0;
      left: 0;
      padding: 0;
      align-items: center;
      transition: none;
    }

    &--item {
      font-family: "Abraham";
      font-weight: 400;
      font-size: 1.8rem;
      cursor: pointer;
      color: $color-black;

      &-maplink {
        color: $color-black;
        text-decoration: none;
      }
      &-social {
        height: 4.8rem;
      }
    }
  }

  &__sticky {
    position: fixed;
    bottom: 2.4rem;
    right: 2.4rem;
    z-index: 1;
    &--img {
      height: 5.6rem;
    }

    display: grid;
    gap: 1.6rem;
  }
}

.overlay {
  background-color: rgba(0, 0, 26, 0.5);
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  transition: opacity 0.5s;
}

.hide {
  opacity: 0;
  width: 0;
}

.show {
  right: 0;
}
