.form {
  text-align: right;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 auto $margin-section-mobile;
  border-radius: 1rem;
  padding: 2.4rem;

  @include respond(tablet) {
    padding: 4.8rem;
    margin: 0 auto $margin-section-tablet;
  }

  @include respond(tablet) {
    margin: 0 auto $margin-section-desktop;
  }
  &__headline {
    margin-bottom: 2.4rem;
  }
  &__text {
    font-size: 1.8rem;
    line-height: 2rem;
    margin-bottom: 1.6rem;
    @include respond(tablet) {
      margin-bottom: 2.4rem;
    }

    &--link {
      color: $color-blue;
      text-decoration: none;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: max-content repeat(2, 1fr);
    gap: 1.6rem;
    align-items: center;
    @include respond(tablet) {
      grid-template-columns: max-content min-content 1fr;
    }
    & label {
      font-size: 1.8rem;
      grid-column: 1 / 2;
    }
    & input {
      max-width: 14rem;
      height: 3.2rem;
      border-radius: 10rem;
      border: 1px solid $color-blue;
      outline: none;
      padding: 1rem;
    }

    &--btn {
      height: 3.2rem;
      max-width: 8rem;
      font-size: 1.4rem;
    }
  }
}
