.map {
  margin-bottom: $margin-section-mobile;

  @include respond(tablet) {
    margin-bottom: $margin-section-tablet;
  }

  @include respond(desktop) {
    margin-bottom: $margin-section-desktop;
  }
  &__headline {
    margin-bottom: 2.4rem;
  }

  &__frame {
    width: 32.7rem;
    height: 32.7rem;

    @include respond(tablet) {
      width: 60rem;
      height: 40rem;
    }
  }
}
