.header {
  max-width: 100%;
  margin-bottom: $margin-section-mobile;

  @include respond(tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: $margin-section-tablet;
    margin-inline: auto;
  }
  @include respond(desktop) {
    margin-bottom: $margin-section-desktop;
  }

  &__content {
    padding: 0 2.4rem;

    @include respond(tablet) {
      align-self: center;
    }
  }

  &__hero {
    position: relative;
    z-index: -1;
    &--img {
      width: 60%;

      border-radius: 50%;
      @include respond(tablet) {
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0);
    }
  }

  &__headline {
    font-size: 3.2rem;
    margin-bottom: 1.2rem;
    @include respond(tablet) {
      text-align: right;
    }
    @include respond(desktop) {
      font-size: 4.8rem;
      margin-bottom: 2.4rem;
    }
  }

  &__text {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
    line-height: 2rem;

    @include respond(tablet) {
      text-align: right;
    }
    @include respond(desktop) {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 2.4rem;
    }
  }

  &__btn {
    width: 15rem;
    height: 3.2rem;
    margin-bottom: 2.4rem;
    @include respond(desktop) {
      height: 4rem;
      width: 20rem;
      margin-bottom: 0;
    }
  }
}
