*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent;
}

body {
  text-align: right;
  font-family: "Comix", sans-serif;
  background-color: hsl(36, 100%, 96%);
  color: #7a746e;
  letter-spacing: 1px;
  position: relative;
}

@font-face {
  font-family: "Comix";
  src: url(../assets/comix.ttf);
}
@font-face {
  font-family: "Abraham";
  src: url(../assets/Abraham.ttf);
}
p {
  font-family: "Comix";
}

h1,
h2,
h3,
h4 {
  font-family: "Abraham", sans-serif;
  color: #030303;
}

h2 {
  font-size: 3.2rem;
}

.container {
  max-width: 37.5rem;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 620px) {
  .container {
    max-width: 76.8rem;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 120rem;
  }
}

.freepik {
  margin: 0 auto;
  text-align: center;
}

.btn {
  font-family: inherit;
  font-family: "Abraham";
  border: none;
  background-color: #748ffc;
  color: #fffdfa;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1.8rem;
  letter-spacing: 1px;
  padding-block: 0 0.45rem;
}
@media (min-width: 1024px) {
  .btn {
    font-size: 2.4rem;
  }
}
.btn:hover {
  background-color: #91a7ff;
}

.topnav {
  background-color: hsl(36, 100%, 96%);
  display: grid;
  grid-template-columns: repeat(2, min-content);
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2.4rem 1.6rem 1.6rem;
  position: sticky;
  top: 0;
  z-index: 1;
}
@media (min-width: 1024px) {
  .topnav {
    margin-bottom: 10rem;
    padding: 0.5rem 3.2rem 1.6rem 2.4rem;
    grid-template-columns: 1fr min-content;
  }
}
.topnav__logo {
  cursor: pointer;
}
.topnav__logo--img {
  height: 6.4rem;
}
.topnav__icon {
  z-index: 2;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .topnav__icon {
    display: none;
  }
}
.topnav__menu {
  position: fixed;
  text-align: right;
  right: 0;
  top: 0;
  height: 100vh;
  width: 25rem;
  right: -25rem;
  background-color: #fffdfa;
  padding: 10rem 4.8rem 0 0;
  display: grid;
  grid-template-rows: repeat(8, max-content);
  row-gap: 2.4rem;
  transition: 0.5s ease-in-out;
  z-index: 1;
}
@media (min-width: 1024px) {
  .topnav__menu {
    position: relative;
    background: none;
    grid-template-columns: repeat(9, max-content);
    -moz-column-gap: 4rem;
         column-gap: 4rem;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;
    right: 0;
    left: 0;
    padding: 0;
    align-items: center;
    transition: none;
  }
}
.topnav__menu--item {
  font-family: "Abraham";
  font-weight: 400;
  font-size: 1.8rem;
  cursor: pointer;
  color: #030303;
}
.topnav__menu--item-maplink {
  color: #030303;
  text-decoration: none;
}
.topnav__menu--item-social {
  height: 4.8rem;
}
.topnav__sticky {
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  z-index: 1;
  display: grid;
  gap: 1.6rem;
}
.topnav__sticky--img {
  height: 5.6rem;
}

.overlay {
  background-color: rgba(0, 0, 26, 0.5);
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  transition: opacity 0.5s;
}

.hide {
  opacity: 0;
  width: 0;
}

.show {
  right: 0;
}

.header {
  max-width: 100%;
  margin-bottom: 5.6rem;
}
@media (min-width: 620px) {
  .header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 10rem;
    margin-inline: auto;
  }
}
@media (min-width: 1024px) {
  .header {
    margin-bottom: 16rem;
  }
}
.header__content {
  padding: 0 2.4rem;
}
@media (min-width: 620px) {
  .header__content {
    align-self: center;
  }
}
.header__hero {
  position: relative;
  z-index: -1;
}
.header__hero--img {
  width: 60%;
  border-radius: 50%;
}
.header__hero::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
}
.header__headline {
  font-size: 3.2rem;
  margin-bottom: 1.2rem;
}
@media (min-width: 620px) {
  .header__headline {
    text-align: right;
  }
}
@media (min-width: 1024px) {
  .header__headline {
    font-size: 4.8rem;
    margin-bottom: 2.4rem;
  }
}
.header__text {
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
  line-height: 2rem;
}
@media (min-width: 620px) {
  .header__text {
    text-align: right;
  }
}
@media (min-width: 1024px) {
  .header__text {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 2.4rem;
  }
}
.header__btn {
  width: 15rem;
  height: 3.2rem;
  margin-bottom: 2.4rem;
}
@media (min-width: 1024px) {
  .header__btn {
    height: 4rem;
    width: 20rem;
    margin-bottom: 0;
  }
}

.features {
  background-color: hsl(36, 100%, 96%);
  margin-bottom: 5.6rem;
}
@media (min-width: 620px) {
  .features {
    margin-bottom: 10rem;
  }
}
@media (min-width: 1024px) {
  .features {
    margin-bottom: 16rem;
  }
}
.features__headline {
  margin: 0 auto;
  padding-bottom: 0.5rem;
  margin-bottom: 2.4rem;
}
@media (min-width: 620px) {
  .features__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.4rem;
    margin-bottom: 2.4rem;
  }
}
@media (min-width: 1024px) {
  .features__content {
    grid-template-columns: repeat(4, 1fr);
  }
}
.features__item {
  margin: 0 2.4rem 2.4rem;
  padding: 2.4rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5rem 0.5rem 5rem 1rem rgba(0, 0, 0, 0.1);
}
@media (min-width: 620px) {
  .features__item {
    margin: 0;
  }
}
.features__item--icon {
  margin-bottom: 0.2rem;
}
.features__item--icon-img {
  height: 3.2rem;
}
.features__item--headline {
  margin-bottom: 1rem;
  font-size: 1.8rem;
}
.features__item--text {
  margin: 0 auto;
  font-size: 1.8rem;
  line-height: 2rem;
}
.features__item--text-maplink {
  color: #748ffc;
  text-decoration: none;
  cursor: pointer;
}
.features__btn {
  width: 15rem;
  height: 3.2rem;
}
@media (min-width: 1024px) {
  .features__btn {
    height: 4rem;
    width: 20rem;
  }
}

.gallery {
  margin: 0 auto 3.2rem;
  overflow: hidden;
  position: relative;
  height: 18rem;
  z-index: -1;
}
@media (min-width: 620px) {
  .gallery {
    height: 40rem;
  }
}
.gallery__headline {
  margin-bottom: 2.4rem;
}
.gallery__track {
  list-style: none;
  transition: 0.5s ease-in-out;
}
.gallery__track--slide {
  position: absolute;
  left: 0;
  right: 0;
}
.gallery__track--slide-img {
  height: 18rem;
  width: 27rem;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 1rem;
}
@media (min-width: 620px) {
  .gallery__track--slide-img {
    height: 40rem;
    width: 46rem;
  }
}
.gallery__btns {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  justify-content: center;
  gap: 1.6rem;
  margin-bottom: 5.6rem;
}
@media (min-width: 620px) {
  .gallery__btns {
    margin-bottom: 10rem;
  }
}
@media (min-width: 1024px) {
  .gallery__btns {
    margin-bottom: 16rem;
  }
}
.gallery__btns--btn {
  background-color: #030303;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
  transition: all 0.2s;
}
.gallery__btns--btn:hover {
  background-color: #748ffc;
}

.video {
  margin: 0 auto 3.2rem;
  overflow: hidden;
}
@media (min-width: 620px) {
  .video {
    height: 31.5rem;
  }
}
.video__headline {
  margin-bottom: 2.4rem;
}
.video__track {
  list-style: none;
  transition: 0.5s ease-in-out;
  display: grid;
  grid-template-columns: repeat(5, min-content);
  place-content: center;
  grid-gap: 2.4rem;
}
.video__track--slide-vid {
  border-radius: 1rem;
}
.video__btns {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  justify-content: center;
  gap: 1.6rem;
  margin-bottom: 5.6rem;
}
@media (min-width: 620px) {
  .video__btns {
    margin-bottom: 10rem;
  }
}
@media (min-width: 1024px) {
  .video__btns {
    margin-bottom: 16rem;
  }
}
.video__btns--btn {
  background-color: #030303;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
  transition: all 0.2s;
}
.video__btns--btn:hover {
  background-color: #748ffc;
}

.bio {
  text-align: center;
  background-color: hsl(36, 100%, 96%);
  margin-bottom: 5.6rem;
}
@media (min-width: 620px) {
  .bio {
    display: grid;
    grid-template-columns: min-content max-content;
    align-items: center;
    justify-content: center;
    gap: 6.4rem;
    margin-bottom: 10rem;
  }
}
@media (min-width: 1024px) {
  .bio {
    margin-bottom: 16rem;
  }
}
.bio__avatar {
  margin-bottom: 1.6rem;
}
.bio__avatar--img {
  border-radius: 50%;
  max-width: 24rem;
}
.bio__headline {
  text-align: center;
  margin-bottom: 2.4rem;
}
.bio__content {
  padding: 2.4rem;
  margin: 0 2.4rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5rem 0.5rem 5rem 1rem rgba(0, 0, 0, 0.1);
  max-width: 32.7rem;
}
.bio__content--icon-img {
  height: 4.8rem;
}
.bio__content--text {
  font-size: 1.8rem;
  line-height: 2rem;
}

.form {
  text-align: right;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 auto 5.6rem;
  border-radius: 1rem;
  padding: 2.4rem;
}
@media (min-width: 620px) {
  .form {
    padding: 4.8rem;
    margin: 0 auto 10rem;
  }
}
@media (min-width: 620px) {
  .form {
    margin: 0 auto 16rem;
  }
}
.form__headline {
  margin-bottom: 2.4rem;
}
.form__text {
  font-size: 1.8rem;
  line-height: 2rem;
  margin-bottom: 1.6rem;
}
@media (min-width: 620px) {
  .form__text {
    margin-bottom: 2.4rem;
  }
}
.form__text--link {
  color: #748ffc;
  text-decoration: none;
}
.form__form {
  display: grid;
  grid-template-columns: max-content repeat(2, 1fr);
  gap: 1.6rem;
  align-items: center;
}
@media (min-width: 620px) {
  .form__form {
    grid-template-columns: max-content min-content 1fr;
  }
}
.form__form label {
  font-size: 1.8rem;
  grid-column: 1/2;
}
.form__form input {
  max-width: 14rem;
  height: 3.2rem;
  border-radius: 10rem;
  border: 1px solid #748ffc;
  outline: none;
  padding: 1rem;
}
.form__form--btn {
  height: 3.2rem;
  max-width: 8rem;
  font-size: 1.4rem;
}

.footer {
  margin-bottom: 5.6rem;
}
@media (min-width: 620px) {
  .footer {
    margin-bottom: 10rem;
  }
}
@media (min-width: 1024px) {
  .footer {
    margin-bottom: 16rem;
  }
}
.footer__nav {
  display: grid;
  gap: 1rem;
}
@media (min-width: 620px) {
  .footer__nav {
    grid-template-columns: repeat(8, max-content);
    gap: 1.6rem;
    align-items: center;
    text-align: center;
    place-content: center;
    margin: 0 auto;
  }
}
.footer__nav--item {
  font-family: "Abraham";
  font-size: 1.8rem;
  color: black;
  cursor: pointer;
}
.footer__nav--item-maplink {
  color: #030303;
  text-decoration: none;
}
.footer__nav--item-facebook {
  height: 4.8rem;
}

.map {
  margin-bottom: 5.6rem;
}
@media (min-width: 620px) {
  .map {
    margin-bottom: 10rem;
  }
}
@media (min-width: 1024px) {
  .map {
    margin-bottom: 16rem;
  }
}
.map__headline {
  margin-bottom: 2.4rem;
}
.map__frame {
  width: 32.7rem;
  height: 32.7rem;
}
@media (min-width: 620px) {
  .map__frame {
    width: 60rem;
    height: 40rem;
  }
}/*# sourceMappingURL=App.css.map */