.gallery {
  margin: 0 auto 3.2rem;
  overflow: hidden;
  position: relative;
  height: 18rem;
  z-index: -1;

  @include respond(tablet) {
    height: 40rem;
  }

  &__headline {
    margin-bottom: 2.4rem;
  }

  &__track {
    list-style: none;
    transition: 0.5s ease-in-out;
    &--slide {
      position: absolute;
      left: 0;
      right: 0;
      &-img {
        height: 18rem;
        width: 27rem;
        object-fit: contain;
        border-radius: 1rem;
        @include respond(tablet) {
          height: 40rem;
          width: 46rem;
        }
      }
    }
  }

  &__btns {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    justify-content: center;
    gap: 1.6rem;
    margin-bottom: $margin-section-mobile;
    @include respond(tablet) {
      margin-bottom: $margin-section-tablet;
    }
    @include respond(desktop) {
      margin-bottom: $margin-section-desktop;
    }

    &--btn {
      background-color: $color-black;
      width: 6.4rem;
      height: 6.4rem;
      border-radius: 50%;
      display: grid;
      place-content: center;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: $color-blue;
      }
    }
  }
}
