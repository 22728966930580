// Colors
$color-white: #fffdfa;
$color-cream: hsl(36, 100%, 96%);
$color-black: #030303;
$color-gray: #7a746e;
$color-blue: #748ffc;

// Margins
$margin-section-mobile: 5.6rem;
$margin-section-tablet: 10rem;
$margin-section-desktop: 16rem;

// Media
@mixin respond($breakpoint) {
  @if $breakpoint == tablet {
    @media (min-width: 620px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (min-width: 1024px) {
      @content;
    }
  }
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent;
}

body {
  text-align: right;
  font-family: "Comix", sans-serif;
  background-color: $color-cream;
  color: $color-gray;
  letter-spacing: 1px;
  position: relative;
}

@font-face {
  font-family: "Comix";
  src: url(../assets/comix.ttf);
}
@font-face {
  font-family: "Abraham";
  src: url(../assets/Abraham.ttf);
}

p {
  font-family: "Comix";
}

h1,
h2,
h3,
h4 {
  font-family: "Abraham", sans-serif;
  color: $color-black;
}

h2 {
  font-size: 3.2rem;
}

.container {
  max-width: 37.5rem;
  margin: 0 auto;
  text-align: center;

  @include respond(tablet) {
    max-width: 76.8rem;
  }
  @include respond(desktop) {
    max-width: 120rem;
  }
}

.freepik {
  margin: 0 auto;
  text-align: center;
}

.btn {
  font-family: inherit;
  font-family: "Abraham";
  border: none;
  background-color: $color-blue;
  color: $color-white;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1.8rem;
  letter-spacing: 1px;
  padding-block: 0 0.45rem;
  @include respond(desktop) {
    font-size: 2.4rem;
  }

  &:hover {
    background-color: #91a7ff;
  }
}
