.features {
  background-color: $color-cream;
  margin-bottom: $margin-section-mobile;

  @include respond(tablet) {
    margin-bottom: $margin-section-tablet;
  }
  @include respond(desktop) {
    margin-bottom: $margin-section-desktop;
  }

  &__headline {
    margin: 0 auto;
    padding-bottom: 0.5rem;
    margin-bottom: 2.4rem;
  }

  &__content {
    @include respond(tablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2.4rem;
      margin-bottom: 2.4rem;
    }

    @include respond(desktop) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__item {
    margin: 0 2.4rem 2.4rem;
    padding: 2.4rem;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0.5rem 0.5rem 5rem 1rem rgba(0, 0, 0, 0.1);

    @include respond(tablet) {
      margin: 0;
    }
    &--icon {
      margin-bottom: 0.2rem;
      &-img {
        height: 3.2rem;
      }
    }
    &--headline {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
    &--text {
      margin: 0 auto;
      font-size: 1.8rem;
      line-height: 2rem;

      &-maplink {
        color: $color-blue;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  &__btn {
    width: 15rem;
    height: 3.2rem;

    @include respond(desktop) {
      height: 4rem;
      width: 20rem;
    }
  }
}
