.footer {
  margin-bottom: $margin-section-mobile;

  @include respond(tablet) {
    margin-bottom: $margin-section-tablet;
  }
  @include respond(desktop) {
    margin-bottom: $margin-section-desktop;
  }
  &__nav {
    display: grid;
    gap: 1rem;

    @include respond(tablet) {
      grid-template-columns: repeat(8, max-content);
      gap: 1.6rem;
      align-items: center;
      text-align: center;
      place-content: center;
      margin: 0 auto;
    }
    &--item {
      font-family: "Abraham";
      font-size: 1.8rem;
      color: black;
      cursor: pointer;
      &-maplink {
        color: $color-black;
        text-decoration: none;
      }

      &-facebook {
        height: 4.8rem;
      }
    }
  }
}
